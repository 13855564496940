<template>
  <div class="boxed-container">
    <nav class="navbar horizontal-menu navbar-fixed-top">
      <div class="navbar-inner">
        <div class="navbar-brand">
          <router-link to="/" class="logo">
            <img src="../assets/images/logo_dark@2x.png" width="100%" alt="" class="hidden-xs">
            <img src="../assets/images/logo@2x.png" width="100%" alt="" class="visible-xs">
          </router-link>
        </div>
        <div class="navbar-mobile-clear"></div>
        <!-- <a href="https://github.com/Anjaxs/WebStack-vue" target="_blank">
           <img style="position: absolute; top: 0; right: 0; border: 0;"
               src="https://s3.amazonaws.com/github/ribbons/forkme_right_darkblue_121621.png"
               alt="Fork me on GitHub">
        </a> -->
      </div>
    </nav>

    <div class="page-container">
      <div class="main-content" style="">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <!-- 关于网站 -->
              <h4 class="text-gray">关于网站</h4>
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12">
                    
                    <blockquote>
                      Forked From <a href="https://github.com/Anjaxs/WebStack-vue">Anjaxs/WebStack-vue</a>, 鸣谢<a href="http://www.viggoz.com/">Viggo</a>大佬！
                    </blockquote>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <!-- <Footer /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Footer from '../components/Footer.vue'

export default {
  name: 'About',
  components: {
    // Footer
  },
  data() {
    return {
      authorImg: './assets/images/logos/viggoz.png'
    }
  }
}
</script>

<style>
</style>
